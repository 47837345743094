<template>
  <div class="tab-container">
  <!-- <div class="txt">Участники голосования</div> -->
    <el-row>
      <el-col :span="8"><span class="txt">Участники голосования</span></el-col>
      <el-col :span="15" v-if="$store.getters['curElect']" class="buttxt"> Текущее голосование: <span class="txt">{{$store.getters['curElect'].name}}.</span></el-col>
    </el-row>
   <el-table
    :data="options"
    border
    @row-click="selrow"
    @row-contextmenu="contextmenu"
    :row-style="tableRowStyle"
    :default-sort = "{prop: 'name_f'}"
    height="800"
    style="width: 100%">
    <el-table-column
      type="index"
      width="50">
    </el-table-column>
    <!-- <el-table-column width="50">
      <i class="el-icon-menu" style="font-size: 1.2rem;color:lightblue"/>
    </el-table-column> -->
    <el-table-column v-for="(col) in columns" 
      :label="col.label" :prop="col.prop" :key="col.prop" 
      :sortable="col.sortable?true:false" :width="col.width" :sort-method="col.prop=='boxes'?sort:null">
      <template slot-scope="scope">
        <span :class="col.class" >{{ scope.row[col.prop] }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="Участвует"
      prop="dis"
      width="150" sortable>
      <template slot-scope="scope">
        <span :style="(scope.row.dis?'color:red':'color:green')+';font-size: 1.2rem;'">
          <i :class="scope.row.dis?'el-icon-close':'el-icon-check'"></i>
        </span>
      </template>
    </el-table-column>
    <el-table-column
      v-if="$store.getters['curElect']"
      label="Проголосовал"
      prop="action"
      width="120" sortable/>
   </el-table> 
   <el-dialog title="Редактирование" :visible.sync="diaVis">
    <Udata mode="list" :userin="userin" @upduser="upduser" @deluser="deluser" @genpass="gen_pass"/>
   </el-dialog>
 </div>
</template>

<script>
import axios from 'axios'
import Udata from '../components/Udata'
import { hostus, genPass } from "../utils/func.js";

//const hostus = (process.env.NODE_ENV == "development" ? 'http://localhost:3000' : '')

export default {
  components: { Udata },
  data() {
    return {
      diaVis: false,
      //userid: null,
      isAdmin: () => this.$store.getters['isAdmin'],
      username: '',
      user: {},
      options: [],
      columns: [
        {label:"Фамилия", prop: "name_f", sortable: true, class: "txt", width: ""},
        {label:"Имя", prop: "name_i", sortable: true, class: "txt" },
        {label:"Отчество", prop: "name_o", sortable: true, class: "txt" },
        {label:"Идент. №", prop: "boxes", sortable: true, class: "txt", width: "" },
        {label:"Телефон", prop: "tel", width: "150" },
        {label:"Логин", prop: "username", sortable: true, width: "" },
        {label:"Группа", prop: "grup", sortable: true, width: "" },
        //{label:"Участвует", prop: "dis", sortable: true, width: "" },
      ]
    }
  },
  mounted: async function () { 
    let result = await axios.post(`${hostus}/mysqlus/select`, { username: '*' })
    //console.log('result=', result.data,this.$store.getters["curResult"])
    this.options = result.data
    
    const res = this.$store.getters["curResult"]
    if (res) {
      this.options.forEach((row) => { // отметить проголосовавших
        let x = res.find( (item) => row.boxes == item.username.split(' ').at(-1) )
        row.action = x ? '+' : ''
      })
    }
  },
  computed: {
    userin () {/* console.log('this.user=',this.user); */return this.user}
  },
  methods: {
    contextmenu (row,col,event) {
      const url = new URL(window.location.href)
      console.log(window.location.search, url.searchParams.get('key')) 
      console.log(process.env)
      console.log('row=',row,'col=', col,'event=',event)
    },
    sort (a, b) {  
      let an=Number(a.boxes);
      let bn=Number(b.boxes); 
      return an - bn
    },
    selrow (row) {
      //console.log('row=', row)
      this.user = row //Object.assign({}, row) 
      if (this.isAdmin()) this.diaVis = true
    },
    tableRowStyle ({row}) {
      /* const res = this.$store.getters["curResult"]
      if (res) {
        let x = res.find((item) => {
          return row.boxes == item.username.split(' ').at(-1)
        } )
        if (x) { return {background: "#e0f1d7"}}
      } */
      return row.action ? {background: "#e0f1d7"} : {}
    },
    upduser (updus) { // после редактирования user
      //console.log('updus=', updus)
      if (updus) {
        const props = Object.getOwnPropertyNames(updus) 
        props.forEach( prop => { 
          if (prop != 'id' && prop != 'uId')
            if (this.user[prop] !== updus[prop]) this.user[prop] = updus[prop];
        });
      }
      this.diaVis = false
    },
    /* setuser () {
      let id = this.userid
      let user = this.options.find(el => el.id == id)
      this.username = user.username
      for (let im in user) if (im != 'password' && im != 'username') this.user[im] = user[im]
      this.user = Object.assign({}, this.user)
      //console.log('this.user=',this.user)
    }, */
    deluser () {
      let title = `Удалить из базы ${this.user.username} ?`
      this.$confirm(title, 'Подтвердите удаление', {confirmButtonText: 'Да', cancelButtonText: 'Отмена', type: 'warning'})
        .then(() => axios.delete(`${hostus}/mysqlus/delete`, {data: { id: this.user.id }}).then((res) => {
          //console.log('res=',res)
          if (res.data.okmsg) {
            let ind = this.options.findIndex(el => el.id == this.user.id)
            this.options.splice(ind, 1)
            this.user = null
            this.$alert(res.data.okmsg, "Успешно", {type: 'success'});
          }
          else this.$alert(res.data.errmsg, "Ошибка ", {type: 'error', confirmButtonText: 'Закрыть', showClose: false})
          this.diaVis = false
        }).catch(() => { }) 
        ).catch(() => { })
    },
    gen_pass () {
      let pass = genPass(this.user)
      this.$confirm(`Установить пароль по умолчанию ? Будет пароль: ${pass}`, 'Подтвердите изменение пароля', {type: 'warning'})
        .then(() => {
          //console.log('pass=', pass)
          let updInfo = { id: this.user.id, password: pass }
          this.$store.dispatch('user/update', updInfo).then((msg) => {
            if (msg != "") {
              this.$alert(msg, "Ошибка при изменении", {type: 'error'})
            }
            else { 
              this.$alert("Успешно", "", {type: 'success'})
            }
          }).catch(() => { })
          this.diaVis = false
        }).catch(() => { this.diaVis = false })
    }
  },
}
</script>

<style  scoped>
.tab-container {
    position: relative;
    width: 98%;
    max-width: 100%;
    padding: 5px 0px 10px;
    margin: 0 auto;
  background-color:lightgray;
  overflow: hidden;
}
.butt{
  margin-left:60px;
  margin-top:0px;
  margin-bottom:20px;
}
.buttxt {
  font-size: .8rem;
  padding: 4px;
  text-align: right;
  color:darkslategrey;
}
.txt {
  font-size: 1rem;
  margin: 8px;
  color: navy;
}
  .el-table .success-row {
    background: #e0f1d7;
  }

</style>
<template>
  <div>
  <el-form class="login-form" ref="cabiForm" v-bind:model="user" :rules="rules" label-position="left" label-width="100px" >
    <el-form-item label="Фамилия:" size="small" prop='name_f'>
      <el-input v-model="user.name_f" size="small" type='text'/>
    </el-form-item>
    <el-form-item label="Имя:" size="small" prop='name_i'>
      <el-input v-model="user.name_i" size="small" type='text'/>
    </el-form-item>
    <el-form-item label="Отчество:" size="small" prop='name_o'>
      <el-input v-model="user.name_o" size="small" type='text'/>
    </el-form-item>
    <el-form-item label="Телефон:" size="small" prop='tel'>
      <el-input v-model="user.tel" size="small" type='tel' placeholder="8(999) 999-9999" pattern="8[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}" @input="input_tel" @invalid="invalid_tel"/>
    </el-form-item>
    <!-- <el-form-item label="Email:" size="small" prop='email'>
      <el-input v-model="user.email" size="small" type='email'/>
    </el-form-item> -->
    <el-form-item label="Идент. №:" size="small" prop='boxes'>
      <el-input v-model="user.boxes" size="small" type='text' :disabled="!isAdmin()"/>
    </el-form-item>
    <el-form-item size="small" prop='grup' v-if="isAdmin()">
      <el-select v-model="user.grup" filterable autocomplete placeholder="Группа" no-match-text="Ничего нет">
        <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        >
          <span class="buttxt">{{item.label}} </span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item size="small" v-if="isAdmin() && mode=='list'">
      <el-button plain type="info" @click="$emit('genpass')" style="width:100%">Сброс пароля</el-button> 
    </el-form-item>
    <el-form-item size="small" v-if="isAdmin() && mode=='list'">
      <el-button plain type="danger" @click="$emit('deluser')" style="width:100%">Удаление из базы</el-button> 
    </el-form-item>
    <el-checkbox v-model="dis" border label="Исключить из голосований" v-if="mode=='list'"></el-checkbox>
    <el-button plain class="butt" type="primary" @click="re()"> Отмена</el-button> 
    <el-button class="butt" type="primary" @click="ok('cabiForm')" native-type='submit' :disabled="isEq()"> {{mode == 'reg' ? 'Далее' : 'Изменить'}}</el-button> 
  </el-form>
  <br></div>
</template>

<script>
import { isObjEq } from '@/utils/func'

export default {
  name: 'Udata',
  props: {
    mode: String, userin: Object
  },
  data() {
    const validateName_f = (rule, value, callback) => { //console.log('validate')
      let re = /^[А-ЯЁ]{1}[а-яё]*(-[А-ЯЁ]){0,1}[а-яё]*$/; 
      let ok = re.exec(value)
      if (!ok && value ) {
        callback(new Error('Кириллица, начиная с заглавной буквы'))
      }
      else callback()
    }
    const validateTel = (rule, value, callback) => { 
      let re = /^8[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}$/; 
      let ok = re.exec(value) || value.length == 1 || value == ''
      if (!ok && value ) {
        callback(new Error('Формат номера телефона: 8(xxx) xxx-xxxx'))
      }
      else callback()
    }

    return {
      user: Object.assign({}, (this.mode == 'def' ? this.$store.getters['user_da'] : (this.mode == 'list' ? this.userin : this.$store.getters['user_dae'])) ),
      //user_or: Object.assign({}, (this.mode == 'def' ? this.$store.getters['user_da'] : (this.mode == 'list' ? this.userin : this.$store.getters['user_dae'])) ),
      options: [{
          value: 'user',
          label: 'Пользователь'
        }, {
          value: 'adm',
          label: 'Контролёр'
        }, {
          value: 'admin',
          label: 'Администратор'
        }],
      rules: {
        //password: [{ trigger: 'blur', validator: validatePassword }],
        name_f: [{ trigger: 'change', validator: validateName_f },
                { min: 1, max: 20, required: true, message: 'Поле от 1 до 20 символов', trigger: 'change' }],
        name_i: [{ trigger: 'change', validator: validateName_f },
                { min: 2, required: true, message: 'Поле от 1 до 20 символов', trigger: 'change' }],
        name_o: [{ trigger: 'change', validator: validateName_f },{max: 20, message: 'Не более 20 символов', trigger: 'change' }],
        boxes: [{ min: 1, required: true, message: 'Поле не должно быть пустым', trigger: 'change' }],
        email: [{min: 6, type: 'email', message: 'Введите корректный адрес', trigger: 'blur,change' }],
        tel: [{ trigger: 'change', validator: validateTel },{ message: 'Введите корректный номер телефона', trigger: 'blur,change' }]
      }
    }
  },
  computed: {
    user_or() {return Object.assign({}, (this.mode == 'def' ? this.$store.getters['user_da'] : (this.mode == 'list' ? this.userin : this.$store.getters['user_dae'])) )} , 
    dis: {
      get: function () { 
          if (this.user.dis == 1) return true
          else return false
      },
      set: function (newVal) { this.user.dis = (newVal == true ? 1 : 0) }
    },
  },
  watch: {
    userin(newu) { this.user = Object.assign({}, newu)}
  },
  methods: {
    tmp () {console.log('userb=',this.$store.getters['user_da'],'user=',this.user)},
    isAdmin () { return this.$store.getters['isAdmin'] },
    input_tel (val) { 
      const x = val.replace(/^\+7/, '8').replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
      //console.log('x=',x)
      let value = x[1] == '8' ? x[1] : '8'+x[1]
      value = value + (!x[3] ? x[2] : '(' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : ''));
      this.user.tel = value; 
    },
    invalid_tel () {console.log('refs=', this.$refs)},
    isEq () { return isObjEq(/* this.$store.getters['user_da'] */this.user_or, this.user) },
    re () { console.log('this.user=',this.user)
      this.user = Object.assign({}, this.user_or/* this.$store.getters['user_da'] */) 
      this.$emit('upduser', null)
    },

    ok (cabiForm) {debugger
      const th = this
      this.$refs[cabiForm].validate((valid) => {
        if (valid) {
          const usbe = th.user_or //this.$store.getters['user_da']
          const props = Object.getOwnPropertyNames(usbe) //this.$store.getters['user_da'])
          let updInfo = {id: th.user.id}
          props.forEach(prop => { if (th.user[prop] !== usbe[prop]) updInfo[prop] = th.user[prop] });
          if (th.mode == 'reg') { th.$emit('newudata', updInfo) }
          else // изменение данных в профиле
            th.$store.dispatch('user/update', updInfo).then((msg) => {
              if (msg != "") {
                th.$alert(msg, "Ошибка при изменении", {type: 'error', confirmButtonText: 'Закрыть', showClose: false})
              }
              else { 
                th.$alert("Успешно", "", {type: 'success'})
                th.$emit('upduser', updInfo)
              }
            }).catch(() => { })
        }
        else { // !valid
          th.$alert("Ошибка при заполнении", "", {type: 'error'})
        }
      })
    }

  },
}
</script>

<style  scoped>
.login-form {
    position: relative;
    width: 300px;
    max-width: 100%;
    padding: 20px 35px 0;
    background-color: rgb(218, 243, 237);
    margin: 0 auto;
    overflow: hidden;
}
.butt{
  margin-left:50px;
  margin-top:10px;
  margin-bottom:20px;
}
</style>